import axios from "axios";

// const baseUrl = "http://hrdapps67:3023/api";
const baseUrl = "http://hrdapps67:3012/api";

// const baseUrl = "http://hrdapps67:1027/api";


const httpRequest = {
  //*************************************//
  //       GET CONSTRUCTION VIEW         //
  //*************************************//

  Construction(CustomerCode) {
    return Promise.resolve(
      axios.get(`${baseUrl}/constructionview/${CustomerCode}`)
    );
  },

  //***************************************************//
  //       GET CONSTRUCTIONS EMAIL INFORMATION         //
  //***************************************************//

  ConstructionEmailInformation(CustomerCode) {
    return Promise.resolve(
      axios.get(`${baseUrl}/constructionEmail/${CustomerCode}`)
      // axios.get(`http://10.169.140.114:3012/api/constructionEmail/${CustomerCode}`)

    );
  },
  //***************************************************//
  //         GET PLANNER / SALESMAN HOLIDAY            //
  //***************************************************//
  Calendar() {
    return Promise.resolve(axios.get(`${baseUrl}/calendar`));
  },
};

export default httpRequest;
