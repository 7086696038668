<template>
  <div fluid style="width: 100%">
    <center>
      <!-- <div class="mr-2"> -->
      <!-- USER INFO COMPONENT -->
      <!-- <SearchConfirmationComponent :jobkinds="jobkinds" :userEmail="SenderEmail" /> -->
      <!-- </div> -->
      <v-card class="mx-auto" height="auto" style="background-color: #fff">
        <v-card-title
          class="justify-center text-h6"
          style="background-color: #df6464; color: #ffffff; text-transform: uppercase"
        >
          {{ department }} INQUIRY SUMMARY MONITORING
          <!-- <v-file-input id="fileUpload" @change="uploadCSV()" v-model="files" /> -->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="2" lg="2" xl="2">
              <span><b>SECTION:</b></span>
            </v-col>
            <v-col md="2" lg="2" xl="2">
              {{ section }}
              <!-- <v-autocomplete dense outlined v-model="ExtractionSection"></v-autocomplete> -->
            </v-col>
            <v-col md="2" lg="1" xl="2">
              <span><b>TEAM:</b></span>
            </v-col>
            <v-col md="2" lg="2" xl="2">
              {{ team }}
              <!-- <v-autocomplete dense outlined v-model="ExtractionTeam"></v-autocomplete> -->
            </v-col>
            <v-col md="2" lg="2" xl="2">
              <span><b>DATE TODAY:</b></span>
            </v-col>
            <v-col md="2" lg="2" xl="2">
              <v-text-field readonly dense outlined v-model="dateToday"></v-text-field>
            </v-col>
          </v-row>
          <!-- NEW ENTRY CARD -->
          <v-row>
            <v-col cols="2">
              <v-card
                @click="filterNewEntry()"
                elevation="4"
                height="90%"
                width="80%"
                outlined
              >
                <v-card-title
                  class="justify-center"
                  style="background-color: #df6464; color: #fff"
                >
                  <v-icon color="#fff">mdi-newspaper</v-icon>NEW ENTRY
                </v-card-title>
                <v-card-text class="mt-10 text-h2"> {{ newEntry }} </v-card-text>
              </v-card>
            </v-col>
            <!-- UNFINISHED CARD -->
            <!-- <v-btn @click="testMethod()">test</v-btn> -->
            <v-col cols="2">
              <v-card
                @click="filterUnfinished()"
                elevation="4"
                height="90%"
                width="100%"
                outlined
              >
                <v-card-title
                  class="justify-center"
                  style="background-color: #df6464; color: #fff"
                >
                  <v-icon color="#fff">mdi-newspaper-remove</v-icon>UNFINISHED
                </v-card-title>
                <v-card-text class="mt-10 text-h2"> {{ unfinished }} </v-card-text>
              </v-card>
            </v-col>
            <!-- FOR FOLLOW UP CARD -->
            <v-col cols="2">
              <v-card
                @click="filterFollowUP()"
                elevation="4"
                height="90%"
                width="100%"
                outlined
              >
                <v-card-title
                  class="justify-center"
                  style="background-color: #df6464; color: #fff"
                >
                  <v-icon color="#fff">mdi-update</v-icon>FOR FOLLOW UP
                </v-card-title>
                <v-card-text class="mt-10 text-h2"> {{ followup }} </v-card-text>
              </v-card>
            </v-col>
            <!-- FINISHED CARD -->
            <v-col cols="2">
              <v-card
                @click="filterFinished(true)"
                elevation="4"
                height="90%"
                width="100%"
                outlined
              >
                <v-card-title
                  class="justify-center"
                  style="background-color: #df6464; color: #fff"
                >
                  <v-icon color="#fff">mdi-newspaper-check</v-icon>FINISHED
                </v-card-title>
                <v-card-text class="mt-10 text-h2"> {{ finishedCount }} </v-card-text>
              </v-card>
            </v-col>
            <!-- EXTRACTIOn -->
            <v-col cols="4">
              <v-card elevation="4" height="100%" width="100%" outlined>
                <v-card-title
                  class="justify-center"
                  style="background-color: #df6464; color: #fff"
                >
                  EXTRACTION
                </v-card-title>
                <v-card-text>
                  <v-select
                    clearable
                    outlined
                    dense
                    :items="items"
                    class="mt-2"
                    v-model="ExtractionBy"
                    prepend-inner-icon="mdi-vector-arrange-above"
                    label="Extraction By"
                  />
                  <v-row style="margin-top: -10px">
                    <v-col>
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :return-value-sync="date1"
                        min-width="auto"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            label="Date From:"
                            prepend-inner-icon="mdi-calendar"
                            v-model="date1"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date1"
                          :show-current="false"
                          scrollable
                          no-title
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :return-value-sync="date2"
                        min-width="auto"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            label="Date To:"
                            prepend-inner-icon="mdi-calendar"
                            v-model="date2"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date2"
                          :show-current="false"
                          scrollable
                          no-title
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-btn dense block @click="GetDataExtractionCount(true)" color="primary"
                    ><v-icon>mdi-application-export</v-icon> Extraction</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <v-btn color="#DF6464" outlined block @click="GetDataExtractionCount(false)"
                >LOAD DATA<v-icon>mdi-refresh</v-icon></v-btn
              > -->
            </v-col>
            <v-col>
              <v-spacer />
              <v-text-field
                style="width: 65%; margin-left: 35%"
                label="Customer Code"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- data table for inquiry summary monitoring-->

          <template>
            <v-progress-circular
              :style="startProgress == EndProgress ? 'display:none;' : ''"
              :size="200"
              :width="15"
              :value="startProgress"
              indeterminate
              color="#DF6464"
            >
              <h1>{{ startProgress }} / {{ EndProgress }}</h1>
            </v-progress-circular>

            <v-data-table
              centered
              :style="startProgress == EndProgress ? '' : 'display:none;'"
              :headers="headers"
              :search="search"
              :items="ExtractedData"
              height="55vh"
              sort-by="Status"
              :sort-desc="true"
              class="elevation-1"
            >
              <!-- CONTROL CODE -->

              <template v-slot:[`item.CustomerCode`]="{ item }">
                <v-row class="ml-7">
                  <v-btn
                    @click="sendingFollowup(item)"
                    v-if="item.Status != 'Finished'"
                    color="#000"
                    icon
                    >{{ item.CustomerCode }}</v-btn
                  >
                  <v-btn v-else color="#000" icon>{{ item.CustomerCode }}</v-btn>
                </v-row>
              </template>
              <!-- DUE DATE -->
              <template v-slot:[`item.DueDate`]="{ item }">
                <v-row>
                  <span
                    v-if="item.DueDate <= dateToday"
                    style="color: red; font-weight: bold"
                  >
                    {{ item.DueDate }}
                  </span>
                  <span v-else-if="!item.DueDate"> No Due Date</span>
                  <span v-else>{{ item.DueDate }}</span>
                </v-row>
              </template>
              <!-- FOLLOW UP 1 -->
              <template v-slot:[`item.Followup1Date`]="{ item }">
                <v-row>
                  <span
                    v-if="
                      item.Status != 'Finished' &&
                      (item.DueDate >= item.Followup1Date) &
                        !item.Followup1Date &
                        (dateToday >= item.DueDate)
                    "
                    style="background-color: #f9d679; color: #f9d679"
                    >_________
                  </span>
                  <span v-else>{{ item.Followup1Date }}</span>
                </v-row>
              </template>
              <!-- FOLLOW UP 2 -->
              <template v-slot:[`item.Followup2Date`]="{ item }">
                <v-row>
                  <span
                    v-if="
                      item.Status != 'Finished' &&
                      item.Followup1Date &&
                      item.Followup1Date <= daySpan &&
                      !item.Followup2Date
                    "
                    style="background-color: #f9d679; color: #f9d679"
                    >_________
                  </span>

                  <span v-else>{{ item.Followup2Date }}</span>
                </v-row>
              </template>
              <!-- Follow UP 3 -->
              <template v-slot:[`item.Followup3Date`]="{ item }">
                <v-row>
                  <span
                    v-if="
                      item.Status != 'Finished' &&
                      item.Followup2Date &&
                      item.Followup2Date <= daySpan &&
                      !item.Followup3Date
                    "
                    style="background-color: #f9d679; color: #f9d679"
                  >
                    _________
                  </span>
                  <span v-else>{{ item.Followup3Date }}</span>
                </v-row>
              </template>
              <!-- FINISHED INQUIRY -->
              <template v-slot:[`item.Status`]="{ item }">
                <v-row>
                  <span v-if="item.Status == 'Finished'"
                    ><v-icon color="primary">mdi-flag</v-icon> {{ item.Status }}</span
                  >
                  <v-btn
                    v-else-if="item.SenderName == senderName"
                    @click="finishedInquiry(item)"
                    color="error"
                    small
                    outlined
                  >
                    <v-icon v-text="`mdi-minus-circle`" /> Unfinished
                  </v-btn>
                  <v-btn v-else color="error" small outlined disabled="false">
                    <v-icon v-text="`mdi-minus-circle`" /> Unfinished
                  </v-btn>
                </v-row>
              </template>
              <!-- Finished Date -->

              <template v-slot:[`item.FinishedDate`]="{ item }">
                <span>{{ item.FinishedDate }}</span>
                <!-- <span v-else-if="!FinishedDate">1</span> -->
              </template>

              <!-- DAY CONSUMED -->

              <template v-slot:[`item.DaysConsumed`]="{ item }">
                <!-- <span>{{ daysConsumed(item) }}</span> -->
                <span>{{ item.DaysConsumed }}</span>
                <!-- <span v-if="item.DaysConsumed">{{ daysConsumed(item) }}</span> -->
                <!-- <span v-else-if="item.DaysConsumed == 0">1</span> -->
                <!-- <span v-if="item.FinishedDate">{{ item.DaysConsumed }}</span> -->
                <!-- <span v-else-if="item.DaysConsumed == 0">1</span> -->
              </template>
              <!-- <template v-slot:[`item.Attachment`]="{ item }">
                <span v-if="item.Attachment">{{ item.Attachment.split("_")[1] }}</span>
                <span v-else>No attachment</span>
              </template> -->
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </center>
    <!-- DIALOG -->
    <v-dialog v-model="dailog" max-width="700px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-card-title class="mt-n5" v-text="`Send Followup`" />
        <v-divider class="mb-3" />
        <!-- SENDER EMAIL -->
        <v-text-field
          label="Sender Email"
          v-model="senderEmail"
          color="red"
          dense
          outlined
          filled
          disabled
        />

        <!-- RECIPIENT EMAIL -->
        <div>
          <v-row class="mt-n5">
            <v-col>
              <v-combobox
                label="Recipient Email"
                color="red"
                v-model="recipientEmail"
                dense
                outlined
                filled
                multiple
                disabled
              />
            </v-col>
            <v-col>
              <v-combobox
                label="Cc Email"
                color="red"
                v-model="ccEmail"
                dense
                outlined
                filled
                multiple
                disabled
              />
            </v-col>
          </v-row>
          <v-row class="mt-n5 mb-2">
            <v-col>
              <v-menu
                class="text-center"
                content-class="elevation-0"
                :close-oncontent-click="false"
                transition="scale-transition"
                offet-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dueDateFollowUp"
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    label="Due Date"
                    filled
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="new Date().toISOString().slice(0, 10)"
                  color="#df6464"
                />
              </v-menu>

              <v-btn
                color="#25bbf7"
                @click="ciDialog = !ciDialog"
                block
                style="color: white"
                >CI Info</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <!-- FOLLOWUP TYPE -->
        <p
          class="mt-n3 mb-n3"
          style="color: grey; font-size: 16px"
          v-text="`Followup Type`"
        />
        <v-radio-group
          @change="CheckFollowup(followupValue)"
          v-model="followupValue"
          ref="radioFollowup"
          dense
          row
        >
          <v-radio
            v-for="(val, i) in followupType"
            :key="i"
            :label="val"
            :value="i + 1"
            color="red"
            :disabled="CheckFollowupStatus(i + 1, followupType.length, followStatus)"
          />
          <v-btn
            @click="addTemplateDialog = !addTemplateDialog"
            color="info"
            width="52%"
            small
            outlined
          >
            <v-icon v-text="`mdi-plus`" small /> Add template</v-btn
          >
        </v-radio-group>
        <!-- TEMPLATE -->

        <v-textarea
          v-model="toSend.Template"
          color="red"
          label="Template"
          filled
          outlined
          dense
          clearable
        />
        <v-divider class="mb-3 mt-n1" />
        <!-- ACTION -->
        <v-row>
          <v-col>
            <!-- v-if this.conditionpreview = true then followupbutton is true -->
            <v-btn
              block
              class="mt-2"
              color="#00cba3"
              outlined
              :disabled="!this.conditionpreview ? true : false"
              @click="sendFollowup(toSend)"
              ><v-icon v-text="`mdi-email-send-outline`" /> Send Followup</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :disabled="!toSend.Template ? true : false"
              @click="ViewPreview()"
              block
              class="mt-2"
              color="#00cba3"
              outlined
              ><v-icon v-text="`mdi-file-find`" />Peview</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- FOR SELECTING FOLLOWUP -->
    <v-dialog
      v-model="selectFollowupDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <v-card-title class="mt-n5" v-text="`Select Template`" />
        <v-divider class="mb-3" />
        <v-list>
          <v-list-item-group color="#df6464" width="100%">
            <v-list-item v-for="(val, index) in followupTemplate" :key="index">
              <v-list-item-content
                @click="
                  (toSend.Template = val.Template.split('<br>').join('\n')),
                    (selectFollowupDialog = !selectFollowupDialog)
                "
              >
                <v-list-item-title
                  class="text-center"
                  style="font-weight: bold; font-size: 17px"
                >
                  {{ val.Title }}
                </v-list-item-title>
                <v-divider class="mt-2" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>
    <!-- FOR SELECTING CI INFO -->
    <v-dialog v-model="ciDialog" max-width="850px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-card-title primary-title v-text="'Customer Information'" />
        <v-divider />
        <div class="" style="max-height: 700px">
          <v-row>
            <v-col>
              <p class="subtitle-1 mb-n3">From</p>
              <v-checkbox
                v-model="EmailAddress"
                @change="AddCustomerInformation(EmailAddress, 'RecipientEmail')"
                v-for="(val, i) in EmailInformation"
                :key="i"
                :value="val"
                class="mb-n7"
                :label="`${i.split('E')[0]} ( ${val} )`"
                color="#df6464"
                multiple
              ></v-checkbox
            ></v-col>
            <v-divider vertical />
            <v-col>
              <p class="subtitle-1 mb-n3">Cc</p>
              <v-checkbox
                v-model="CcAddress"
                @change="AddCustomerInformation(CcAddress, 'CcEmail')"
                v-for="(val, i) in EmailInformation"
                :key="i"
                :value="val"
                class="mb-n7"
                :label="`${i.split('E')[0]} ( ${val} )`"
                color="#df6464"
                multiple
              ></v-checkbox
            ></v-col>
          </v-row>
        </div>
        <v-divider />
      </v-card>
    </v-dialog>
    <!-- DIALOG FOR PREVIEW  -->
    <v-dialog v-model="FollowUp" max-width="800px">
      <v-card class="pa-10" style="background-color: #ffffff">
        <v-divider class="mb-2" />
        <v-container>
          <v-textarea
            rows="1"
            readonly
            flat
            solo
            auto-grow
            dense
            :value="this.templatePreview"
          ></v-textarea>
          <p class="ml-3">
            -----Original Message-----<br />
            From: {{ senderName }}<br />
            Due: {{ dueDateFollowUp }}<br />
            Sent: {{ SentDate }}<br />
            To: {{ recipientEmail }}<br />
            Cc: {{ ccEmail.length ? ccEmail : "" }}<br />
            Subject: {{ globalSubject }}<br />
            Attachment:
            <v-card-text v-if="!followUPAttachment" outlined>
              <v-btn color="#DF6464" outlined>No Attachment</v-btn>
            </v-card-text>
            <v-card-text
              v-else
              v-for="attach in followUPAttachment.split(',')"
              :key="attach"
            >
              <v-btn @click="downloadPdf(attach)" color="#DF6464" outlined>{{
                attach
              }}</v-btn>
            </v-card-text>
          </p>
          <v-textarea
            flat
            readonly
            auto-grow
            solo
            :value="this.contentPreview"
          ></v-textarea>
          <v-progress-circular
            :style="loadingBilog ? 'display:block' : 'display:none'"
            class="ml-10"
            v-model="loadingBilog"
            :size="300"
            :width="15"
            color="#DF6464"
            indeterminate
          ></v-progress-circular>
          <!-- <h3 :style="viewAttachment ? 'display:block' : 'display:none'">Attachment:</h3> -->
          <!-- 
          <iframe
            :src="viewAttachment"
            height="900px"
            width="100%"
            :style="viewAttachment ? 'display:block' : 'display:none'"
          ></iframe> -->
        </v-container>
        <v-divider class="mb-2" />
      </v-card>
    </v-dialog>
    <!-- DIALOG FOR ADDING FOLLOWUP TEMPLATE -->
    <v-dialog
      v-model="addTemplateDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <v-card-title class="mt-n5" v-text="`Add Followup Template`" />
        <v-divider class="mb-3" />

        <!-- DEPARTMENT -->
        <v-text-field
          v-model="toAdd.Department"
          label="Department"
          color="red"
          dense
          outlined
          filled
          disabled
        />

        <!-- SECTION -->
        <v-text-field
          v-model="toAdd.Section"
          label="Section"
          color="red"
          dense
          outlined
          filled
          disabled
        />

        <!-- TEAM -->
        <v-text-field
          v-model="toAdd.Team"
          label="Team"
          color="red"
          dense
          outlined
          filled
          disabled
        />

        <!-- FOLLOWUP TYPE -->
        <p
          class="mt-n3 mb-n3"
          style="color: grey; font-size: 16px"
          v-text="`Followup Type`"
        />
        <v-radio-group v-model="toAdd.FollowupType" ref="addRadioFollowup" dense row>
          <v-radio
            v-for="(val, i) in followupType"
            :key="i"
            :label="val"
            :value="i + 1"
            color="red"
          />
        </v-radio-group>

        <!-- TITLE -->
        <v-text-field
          v-model="toAdd.Title"
          label="Title"
          color="red"
          dense
          outlined
          filled
        ></v-text-field>
        <!-- TEMPLATE -->
        <v-textarea
          v-model="template"
          @keyup="toAdd.Template = template.split('\n').join('<br>')"
          color="red"
          label="Template"
          filled
          outlined
          dense
        />
        <v-divider />
        <!-- ACTION -->
        <v-btn @click="AddTemplate()" class="mt-2" color="#00cba3" block outlined
          ><v-icon v-text="`mdi-email-plus-outline`" /> Insert</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import awsFunction from "../../functions/Attachment";
import _ from "lodash";
import objects from "../../functions/alert";
import FollowupOverdueTable from "../../functions/httpRequest/ExternalConnection/FollowupOverdueTable";
import InquiryStatusTable from "../../functions/httpRequest/ExternalConnection/InquiryStatusTable";
import allConnection from "../../functions/httpRequest/hrdappsAPI/allConnection";
import hrdSql from "../../functions/httpRequest/ExternalConnection/hrdSql";
import EmailSending from "../../functions/email";
import moment from "moment";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { Extraction } from "../../functions/dataExtraction";
import Swal from "sweetalert2";
import keys from "../../functions/alert";
import axios from "axios";
import store from "../../store";
// import SearchConfirmationComponent from "../../components/CorrectionRequestComponent/SearchConfirmation.vue";
const baseUrl = keys.auth.BaseUrl;
const apikey = keys.auth.apiKey;
export default {
  data: () => ({
    loadingBilog: false,
    // dataStatus: "UNFINISHED",
    files: null,
    csvtext: null,
    // viewAttachment: "",

    followUP: false,
    noJobKind: "null",
    noTeam: "null",
    noSection: "null",
    tempArrExtract: [],
    contentPreview: "",
    globalSubject: "",
    followUPAttachment: "",
    followUPPath: "",
    toFollowup: {},
    timeToday: moment().format("hh:mm:ss"),
    FollowUp: "",
    conditionpreview: false,
    templatePreview: "",
    template: "",
    addTemplateDialog: false,
    toSend: { CcEmail: [] },
    toAdd: {},
    followupTemplate: [],
    selectFollowupDialog: false,
    followStatus: 0,
    followupType: ["First", "Second", "Third"],
    followupValue: "",
    CcAddress: [],
    EmailAddress: [],
    EmailInformation: {},
    ciDialog: false,
    dueDateFollowUp: "",
    ccEmail: "",
    SentDate: "",
    recipientEmail: "",
    senderEmail: store.state.userinfo.Email,
    senderName: store.state.userinfo.FullName,
    followupArr: [],
    dailog: false,
    obj: {},
    // sortBy: "FinishedDate",
    // sortDesc: false,
    daySpan: moment().subtract(3, "d").format("YYYY-MM-DD"),
    search: "",
    consumed: 0,
    startConsumed: 0,
    endConsumed: 0,
    interval: {},
    ExtractionBy: "",
    finishedCount: "",
    followup: "",
    newEntry: "",
    unfinished: "",
    value: 0,
    startProgress: "",
    ConfirmationID: "",
    EndProgress: "",
    triggerEvent: 0,
    globalCount: "",
    storeJobKind: store.state.Organization.jobKind.split("/")[2],
    department: store.state.Organization.department,
    section: store.state.Organization.section,
    team: store.state.Organization.team,
    routerPath: "",
    date1: moment().format("YYYY-MM-21"),
    date2: moment().add(1, "M").format("YYYY-MM-20"),
    dateToday: moment().format("YYYY-MM-DD"),
    dateLastMont: moment().subtract(1, "M").format("YYYY-MM-DD"),
    Deptname: "",
    parameters: "",
    ExtractedData: [],
    x: "",
    headers: [
      { text: "No.", value: "id" },
      { text: "Customer Code", value: "CustomerCode" },
      { text: "Inquiry Kind", value: "JapaneseName" },
      { text: "Job Kind", value: "JobKind" },
      { text: "Send Date", value: "ReceivedDate" },
      { text: "Due Date", value: "DueDate" },
      { text: "Follow Up 1", value: "Followup1Date" },
      { text: "Follow Up 2", value: "Followup2Date" },
      { text: "Follow Up 3", value: "Followup3Date" },
      { text: "Status", value: "Status" },
      { text: "Finished Date", value: "FinishedDate" },
      { text: "Days Consumed", value: "DaysConsumed", align: "display-none" },
      { text: "Sender-In-Charge", value: "SenderName" },
      // { text: "Attachment", value: "Attachment" },
    ],
    items: ["New Entry", "Unfinished", "For Follow Up", "Finished"],
  }),
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    checkRoutes() {
      // alert("TEST")
      if (this.parameters == "/InquirySummary") return "";
      else return "InquirySummary/";
    },
    // computedHeaders() {
    //   if (this.dataStatus == "UNFINISHED") {
    //     return this.headers.filter(
    //       (res) => !["FinishedDate", "DaysConsumed"].includes(res.value)
    //     );
    //   } else return this.headers;
    // },
  },
  watch: {
    dialog(newval) {
      if (newval) {
        // console.log(newval);
        this.dueDateFollowUp = this.followupArr[0].Due;
        //Initial Due date
        delete this.toSend.Template;
        for (let x in this.toFollowup) this.$set(this.toSend, x, this.toFollowup[x]);
      }
      this.conditionpreview = false; //ENABLE SEND PREVIEW
    },
    // GET ROUTE AFTER CHANGE
    $route(to) {
      this.parameters = to;
    },
    // REFRESH REPLY AFTER PARAM CHANGE
    "$route.params"() {
      this.getDeptTeamSect();
    },
    ciDialog(newval) {
      if (newval && !this.EmailInformation.length) {
        this.GetCiInformation(this.followupArr[0].CustomerCode);
      }
    },
    addTemplateDialog(newval) {
      if (newval) {
        this.toAdd.Department = store.state.userinfo.Department;
        this.toAdd.Section = store.state.userinfo.Section;
        this.toAdd.Team = store.state.userinfo.Team;
      }
    },
  },
  // components: {
  //   SearchConfirmationComponent: SearchConfirmationComponent,
  // },
  mounted() {
    console.log(this.storeJobKind);
    this.GetDataExtractionCount(false);
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  },
  methods: {
    daysConsumed(item) {
      if (item.Status == "Finished") {
        console.log(item);
      }
    },
    durationAsString(start, end) {
      const duration = moment.duration(
        moment(end, moment.ISO_8601).diff(moment(start, moment.ISO_8601))
      );

      //Get Days
      const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days}` : ""; // if no full days then do not display it at all

      return daysFormatted;
    },

    testMethod() {
      var a = moment("2023-01-20", "YYYY-MM-DD").format("YYYY-MM-DD");
      var b = moment().add(5, "days").format("YYYY-MM-DD");
      // var totalDayConsumed = b.diff(a, "days");
      // let dateToday = moment("2023-01-20", "YYYY-MM-DD").format("YYYY-MM-DD");

      console.log(this.durationAsString(a, b), "1447");
    },
    async downloadPdf(a) {
      console.log();
      const pathViewer = await awsFunction.convertToFileType([
        `${this.followUPPath}/${a}`,
      ]);
      this.loadingBilog = true;
      setTimeout(() => {
        if (a.split(".")[1].toUpperCase() == "PDF") {
          // this.viewAttachment = URL.createObjectURL(pathViewer[0]);
          window.open(URL.createObjectURL(pathViewer[0]));
          this.loadingBilog = false;
        } else {
          awsFunction.download(this.followUPPath, a);
          this.loadingBilog = false;
        }
      }, 3000);
    },
    sendingFollowup(inquiryData) {
      console.log(inquiryData);
      this.followupArr = [];
      this.dailog = true;
      this.followupArr.push(inquiryData);
      this.recipientEmail = JSON.parse(this.followupArr[0].HandleEmail);
      this.ccEmail = this.followupArr[0].SubHandleEmail
        ? JSON.parse(this.followupArr[0].SubHandleEmail)
        : "";
      this.dueDateFollowUp = this.followupArr[0].DueDate;
      this.followStatus = this.followupArr[0].FollowupStatus;
      this.SentDate = this.followupArr[0].ReceivedDate;
      this.followUPAttachment = this.followupArr[0].Attachment;
      this.followUPPath = this.followupArr[0].Path;
      // this.globalSubject = this.followupArr[0].Subject;

      // console.log();
    },
    finishedInquiry(obj) {
      // console.log(obj);
      Swal.fire({
        title: "Are you sure?",
        text: "You want to finish the inquiry",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(this.Reply);
          const updateStatus = {
            ConfirmationID: obj.ConfirmationID,
            Status: "Finished",
            FollowupStatus: 4,
          };
          InquiryStatusTable.UpdateFinish(updateStatus);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "This Inquiry has been finished.",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1600);
          // setTimeout(window.location.reload(), 3000)

          //
        }
      });
    },
    GetData(followupData) {
      this.toFollowup = followupData;
      this.dialog = !this.dialog;
    },
    AddCustomerInformation(email, emailType) {
      this.toSend[emailType] =
        emailType == "RecipientEmail" ? [...this.toFollowup.RecipientEmail] : [];

      for (let val of email) {
        const checkDuplicate = this.toSend[emailType].some((el) => val == el);
        if (!checkDuplicate) this.toSend[emailType].push(val);
      }
    },
    OriginalMessage(ciInfo, subject) {
      // console.log(ciInfo, "912");
      return `
-----Original Message-----<br>
From: ${ciInfo.SenderName}<br>
Due: ${this.dueDateFollowUp}<br>
Sent: ${this.SentDate}<br>
To: ${ciInfo.HandleStaff}<br>
Cc: ${ciInfo.SubHandleStaff || ""}<br>
Subject: ${subject}<br>
Attachment : ${ciInfo.Attachment}<br><br>
${ciInfo.ContentOfInquiry}
`;
    },
    ViewPreview() {
      // this.viewAttachment = "";
      const due =
        !this.dueDateFollowUp || this.dueDateFollowUp == "Invalid date"
          ? ""
          : `返信期限${this.dueDateFollowUp.split("-")[1]}月${
              this.dueDateFollowUp.split("-")[2]
            }日`;

      // EMAIL SUBJECT
      this.globalSubject = `${
        this.followupArr[0].InquiryKind ? `【${this.followupArr[0].InquiryKind}】` : ""
      } ${
        this.followupArr[0].Subject.split("/")[
          this.followupArr[0].Subject.split("/").length - 1
        ]
      } 《${this.followupArr[0].CustomerCode}》${due} `;
      this.FollowUp = true;
      this.templatePreview = this.toSend.Template.split("<br>").join("<\n>");
      this.contentPreview = this.followupArr[0].ContentOfInquiry.split("<br>").join("\n");
      this.conditionpreview = true;
      // console.log(this.templatePreview);
      // console.log(this.FollowUp);
    },
    // end of Preview
    /**
     * * FOR SENDING FOLLOWUP
     * @param {Object} obj to be send in email
     */

    sendFollowup({ ...obj }) {
      // console.log(this.followupArr[0].ConfirmationID);
      // const Cc = "CcEmail" in obj ? 4 : 3;
      // // CHECK VALIDATION
      // if (Object.keys(obj).length < Cc)
      //   return this.$toast.error("Fill-up All Fields", objects.alert);

      this.$confirm({
        title: "Followup",
        message: "Confirm to send followup",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            // ASSIGN
            obj.Template = obj.Template.split("\n").join("<br>");
            // this.recipientEmail = this.recipientEmail;
            obj.CcEmail.push(obj.SenderEmail);
            obj.CcEmail = obj.CcEmail.join(",");

            // DUEDATE
            // this.ciInfo.Due
            const due =
              !this.dueDateFollowUp || this.dueDateFollowUp == "Invalid date"
                ? ""
                : `返信期限${this.dueDateFollowUp.split("-")[1]}月${
                    this.dueDateFollowUp.split("-")[2]
                  }日`;

            // EMAIL SUBJECT
            const subject = `${
              this.followupArr[0].InquiryKind
                ? `【${this.followupArr[0].InquiryKind}】`
                : ""
            } ${
              this.followupArr[0].Subject.split("/")[
                this.followupArr[0].Subject.split("/").length - 1
              ]
            } 《${this.followupArr[0].CustomerCode}》${due} `;

            // FORWARD STUCTURE MESSAGE
            const forwardMessage = `${obj.Template}<br><br>${this.OriginalMessage(
              this.followupArr[0],
              subject
            )}`;

            // ATTACHMENT               `${this.userRecord.FullName} < ${store.state.userinfo.Email}>`,
            const attachment = this.followupArr[0].Attachment
              ? this.followupArr[0].Attachment.split(",").map(
                  (el) => `${this.followupArr[0].Path}/${el}`
                )
              : [];

            const updateStatus = {
              ConfirmationID: this.followupArr[0].ConfirmationID,
              FollowupStatus: this.selectedFollowup,
            };
            await InquiryStatusTable.Update(updateStatus);
            // SENDING EMAIL
            await EmailSending.sendCorrection(
              this.followupArr[0].ConfirmationID,
              this.followupArr[0].Status,
              this.followupArr[0].Department,
              store.state.userinfo.EmployeeCode,
              moment(this.followupArr[0].DueDate).format("YYYY-MM-DD"),
              `${store.state.userinfo.FullName} < ${this.senderEmail}>`,
              this.senderEmail,
              this.recipientEmail,
              this.ccEmail,
              forwardMessage,
              this.followupArr[0].CustomerCode,
              `Fw:${subject}`,
              attachment || []
            )
              .then(() => {
                this.dialog = false;
                this.toSend = { CcEmail: [] };
                this.$toast.warning("Sending follow up please wait", objects.alert);
              })
              .catch(() =>
                this.$toast.error("Something wrong while sending.", objects.alert)
              );

            this.selectedFollowup = null;
            this.$emit("closeDialog");
          }
        },
      });
    },
    AddTemplate() {
      // FOR VALIDATION
      if (Object.keys(this.toAdd).length !== 6)
        return this.$toast.error("Fill-up All Fields", objects.alert);

      // PASS DATA
      const data = this.toAdd;

      // FOR SAVING IN DATABASE
      FollowupOverdueTable.Post(data).then(() => {
        this.$toast.success("Successfully Added", objects.alert);
        this.addTemplateDialog = !this.addTemplateDialog;
      });
    },

    CheckFollowupStatus(number, FollowupLength, status) {
      if (!status) {
        if (number === 1) return false;
        else return true;
      }
      for (let i = 1; i <= FollowupLength; i++) return number !== status + 1;
    },
    CheckFollowup(type) {
      if (!this.followupValue) return;

      //   QUERY TO PASS IN DATABASE
      const obj = {
        Department: store.state.userinfo.Department,
        Section: store.state.userinfo.Section,
        Team: store.state.userinfo.Team,
        FollowupType: type,
      };

      // CONNECTION
      FollowupOverdueTable.Get(obj).then((res) => {
        // FOR NO RECORD
        if (!res.data.length) {
          this.selectedFollowup = null;
          return this.$toast.error("No Record Found.", objects.alert);
        }

        this.selectedFollowup = type;
        this.followupTemplate = res.data;
        this.selectFollowupDialog = !this.selectFollowupDialog;
      });

      // RESET RADIO BUTTON
      setTimeout(() => this.$refs.radioFollowup.reset(), 2000);
    },
    async GetCiInformation(controlNumber) {
      let email = {};
      //GET EMAIL INFORMATION
      await hrdSql
        .ConstructionEmailInformation(controlNumber)
        .then((res) => (email = res.data));

      await allConnection
        .CustomerInformation(controlNumber)
        .then(async (customerInfo) => {
          await allConnection
            .ConstBranchOffice(customerInfo[0].ConstExhibitionCode)
            .then((exhibitionInfo) => {
              this.$set(
                email,
                "ConstBranchEmail",
                exhibitionInfo[0].MailAddress || exhibitionInfo[0].MailAddress2
              );

              email.ConstBranch = exhibitionInfo[0].ContractExhibitionName;
            });
        });

      await allConnection.DesignOffice(controlNumber).then((designOffice) => {
        this.$set(email, "DesignOfficeEmail", designOffice[0].MailAddress);

        email.DesignOffice = designOffice[0].TraderName1;
      });

      for (let x in email)
        if (x.includes("Email"))
          if (email[x]) this.$set(this.EmailInformation, x, email[x]);
    },
    testReports() {
      const firstProcess = () => {
        return new Promise((resolve) => {
          const headers = {
            id: "id",
            CustomerCode: "CustomerCode",
            JapaneseName: "Inquirykind",
            ReceivedDate: "SentDate",
            DueDate: "DueDate",
            Followup1Date: "Followup1Date",
            Followup2Date: "Followup2Date",
            Followup3Date: "Followup3Date",
            Status: "Status",
            FinishedDate: "FinishedDate",
            DaysConsumed: "DaysConsumed",
            SenderName: "SenderName",
            Department: "Department",
            HandleEmail: "HandleEmail",
            HandleStaff: "HandleStaff",
            SubHandleStaff: "SubHandleStaff",
            JobKind: "JobKind",
            Subject: "Subject",
            ContentOfInquiry: "ContentOfInquiry",
            TitleName: "TitleName", // KEY: L
          };
          const excel_data = [headers, ...this.ExtractedData];
          const workbook = new Excel.Workbook();
          const DetailedSheet = workbook.addWorksheet("Detailed");
          // let indexCell = { detailed: 1, summary: 1 };

          DetailedSheet.columns = [
            { key: "A1", width: 17 },
            { key: "B1", width: 17 },
            { key: "C1", width: 30 },
            { key: "D1", width: 25 },
            { key: "E1", width: 17 },
            { key: "F1", width: 20 },
            { key: "G1", width: 20 },
            { key: "H1", width: 17 },
            { key: "I1", width: 17 },
            { key: "J1", width: 17 },
            { key: "K1", width: 17 },
            { key: "L1", width: 30 },
            { key: "M1", width: 17 },
            { key: "N1", width: 30 },
            { key: "O1", width: 30 },
            { key: "P1", width: 30 },
            { key: "Q1", width: 30 },
            { key: "R1", width: 50 },
            { key: "S1", width: 80 },
          ];
          let filterExtractedDate = excel_data.filter((rec) => {
            let newDate = moment(rec.ReceivedDate).format("YYYY-MM-DD");
            if (newDate >= this.date1 && newDate <= this.date2) return rec.CustomerCode;
          });
          // EXTRACTION NEW ENTRY
          if (this.ExtractionBy == "New Entry") {
            let newEntry = excel_data.filter((rec) => {
              if (rec.ReceivedDate == this.dateToday && rec.Status == "Not yet reply")
                return rec;
            });
            filterExtractedDate = Object.assign(newEntry);
          }
          // EXTRACTION UNFINSIHED
          if (this.ExtractionBy == "Unfinished") {
            let Unfinishedlength = this.ExtractedData.filter((rec) => {
              if (!rec.FinishedDate || !rec.Status || rec.Status == "Not yet reply")
                return rec;
            });
            filterExtractedDate = Object.assign(Unfinishedlength);
          }
          // EXTRACTION FOR FOLLOW UP
          if (this.ExtractionBy == "For Follow Up") {
            let FollowUplength = this.ExtractedData.filter((rec) => {
              let todayDate = moment().format("YYYY-MM-DD");
              if (todayDate >= rec.DueDate && !rec.FinishedDate && rec.FollowupStatus < 3)
                return rec;
            });
            let sortFollowUp = _.orderBy(
              FollowUplength,
              [
                function (o) {
                  return o.DueDate;
                },
              ],
              "asc"
            );

            filterExtractedDate = Object.assign(sortFollowUp);
          }
          // EXTRACTION FINISHED
          if (this.ExtractionBy == "Finished") {
            let Finishedlength = this.ExtractedData.filter((rec) => {
              if (rec.Status == "Finished") return rec;
            });
            filterExtractedDate = Object.assign(Finishedlength);
          }

          filterExtractedDate.unshift(headers);
          console.log(filterExtractedDate);
          filterExtractedDate.forEach((value, index) => {
            let column = [
              { key: "A", value: value.id },
              { key: "B", value: value.CustomerCode },
              { key: "C", value: value.JapaneseName },
              { key: "D", value: value.ReceivedDate },
              { key: "E", value: value.DueDate },
              { key: "F", value: value.Followup1Date },
              { key: "G", value: value.Followup2Date },
              { key: "H", value: value.Followup3Date },
              { key: "I", value: value.Status },
              { key: "J", value: value.FinishedDate },
              { key: "K", value: value.DaysConsumed },
              { key: "L", value: value.SenderName },
              { key: "M", value: value.Department },
              { key: "N", value: value.HandleEmail },
              { key: "O", value: value.HandleStaff },
              { key: "P", value: value.SubHandleStaff },
              { key: "Q", value: value.JobKind },
              { key: "R", value: value.Subject },
              { key: "S", value: value.ContentOfInquiry.split("<br>").join("\n") },
            ];
            let headers = value.ReceivedDate == "Customer Code" ? true : false;
            let borders = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            // let alignment = { wrapText: true };
            let headerFont = { name: "Arial", size: 10, bold: true };
            let Font = { name: "Arial", size: 10, bold: false };

            column.forEach((letter) => {
              // DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment =
              //   letter.key == "M" ? alignment : {};
              DetailedSheet.getCell(`${letter.key}${index + 1}`).border = borders;
              DetailedSheet.getCell(`${letter.key}${index + 1}`).font = headers
                ? headerFont
                : Font;
              DetailedSheet.getCell(`${letter.key}${index + 1}`).value = letter.value;
              if (index + 1 > 1) {
                let heightCell = 0;
                if (letter.key == "S") {
                  let kahitano = letter.value.split("\n");
                  heightCell = kahitano.length * 11;
                  DetailedSheet.getRow(index + 1).height = heightCell;
                }
              }
              if (letter.key !== "S") {
                DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment = {
                  vertical: "middle",
                  horizontal: "center",
                };
              }
            });
          });

          const buffer = workbook.xlsx.writeBuffer();
          resolve(buffer);
        });
      };
      firstProcess().then((val) => {
        const blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `HRD Inquiry Data Extraction(${moment().format("YYYY-MM-DD")})`);
      });
    },

    async ExtractData(offset1) {
      if (!this.date1) {
        alert("Please Input Date");
        return false;
      }
      if (!this.date2) {
        alert("Please Input Date");
        return false;
      }
      let x = new Extraction();
      const asyncFunctions = [
        x.printReports(
          `${baseUrl}/getDataExtraction1/${offset1}/${this.department}/${
            this.section == "" ? this.noSection : this.section
          }/${this.team == "" ? this.noTeam : this.team}/${
            !this.storeJobKind ? this.noJobKind : this.storeJobKind
          }/${this.date1}/${this.date2}`,
          apikey
        ),
      ];
      await Promise.race(asyncFunctions);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Data Extracted",
        showConfirmButton: false,
        timer: 1500,
      });
    },

    async GetDataExtractionCount(is_xls) {
      this.ExtractedData = [];

      const { data, status } = await axios.get(
        `${baseUrl}/getDataExtractionCount/${this.department}/${
          this.section == "" ? this.noSection : this.section
        }/${this.team == "" ? this.noTeam : this.team}/${
          !this.storeJobKind ? this.noJobKind : this.storeJobKind
        }`,
        apikey
      );

      if (status == 200) {
        let countData = data[0].InquiryCount;
        let MathCount = Math.floor(countData / 3000);
        this.EndProgress = MathCount;
        for (let i = 0; i <= MathCount; i++) {
          this.startProgress = i;
          if (i == MathCount) {
            if (is_xls) {
              await this.GetDataExtraction(i * 3000, true);
            } else {
              await this.GetDataExtraction(i * 3000, false);
            }
          } else {
            await this.GetDataExtraction(i * 3000, false);
          }
        }
      }
    },

    GetDataExtraction(offset, is_xls) {
      return new Promise((resolve) => {
        axios
          .get(
            `${baseUrl}/getDataExtraction/${offset}/${this.department}/${
              this.section == "" ? this.noSection : this.section
            }/${this.team == "" ? this.noTeam : this.team}/${
              !this.storeJobKind ? this.noJobKind : this.storeJobKind
            }`,
            apikey
          )
          .then((res) => {
            // console.log(res.data);
            res.data.filter((rec) => {
              rec.ReceivedDate = rec.ReceivedDate
                ? moment(rec.ReceivedDate).format("YYYY-MM-DD")
                : "";
              rec.DueDate = rec.DueDate ? moment(rec.DueDate).format("YYYY-MM-DD") : "";
              rec.Followup1Date = rec.Followup1Date
                ? moment(rec.Followup1Date).format("YYYY-MM-DD")
                : "";
              rec.Followup2Date = rec.Followup2Date
                ? moment(rec.Followup2Date).format("YYYY-MM-DD")
                : "";
              rec.Followup3Date = rec.Followup3Date
                ? moment(rec.Followup3Date).format("YYYY-MM-DD")
                : "";
              rec.FinishedDate = rec.FinishedDate
                ? moment(rec.FinishedDate).format("YYYY-MM-DD")
                : "";
              // rec.FinishedDate =
              //   rec.Status == "Finished" && rec.FinishedDate == ""
              //     ? rec.ReceivedDate
              //     : "";
              // COMPUTATION FOR DAYS CONSUMED
              if (!rec.FinishedDate && rec.Status == "Not yet reply") {
                var start = moment(rec.ReceivedDate, "YYYY-MM-DD").format("YYYY-MM-DD");
                var end = moment().format("YYYY-MM-DD");
                const duration = moment.duration(
                  moment(end, moment.ISO_8601).diff(moment(start, moment.ISO_8601))
                );

                //Get Days
                const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
                const daysFormatted = days ? `${days}` : ""; // if no full days then do not display it at all

                rec.DaysConsumed = daysFormatted == 0 ? 0 : daysFormatted;
              } else {
                var a = moment(rec.ReceivedDate);
                var b = moment(rec.FinishedDate);
                var totalDayConsumed = b.diff(a, "days");
                rec.DaysConsumed = totalDayConsumed == 0 ? 1 : totalDayConsumed;
              }
              // rec.DaysConsumed;
            });
            // let firstSort = _.orderBy(res.data, ["DueDate", "Status"], ["ASC", "ASC"]);

            // this.ExtractedData = Object.assign(res.data);
            this.ExtractedData = [...this.ExtractedData, ...res.data];
            this.tempArrExtract = [...this.ExtractedData];

            //length for New Entry
            let newEntrylength = this.ExtractedData.filter((rec) => {
              if (rec.ReceivedDate == this.dateToday && rec.Status == "Not yet reply")
                return rec;
            });
            this.newEntry = newEntrylength.length;
            //length for Unfinished
            let Unfinishedlength = this.ExtractedData.filter((rec) => {
              if (rec.Status == "Not yet reply") return rec;
            });
            this.unfinished = Unfinishedlength.length;
            //length for FollowUp
            let FollowUplength = this.ExtractedData.filter((rec) => {
              let todayDate = moment().format("YYYY-MM-DD");
              if (
                todayDate >= rec.DueDate &&
                rec.Status == "Not yet reply" &&
                rec.FollowupStatus < 3
              )
                return rec;
            });
            this.followup = FollowUplength.length;
            //length for Finished
            let Finishedlength = this.ExtractedData.filter((rec) => {
              if (rec.Status == "Finished") return rec;
            });
            this.finishedCount = Finishedlength.length;
            if (is_xls) {
              //insert function xlsx extract
              this.testReports();
            }
            resolve("OK " + offset);
          });
      });
    },
    filterNewEntry() {
      // this.dataStatus = "UNFINISHED";
      this.ExtractedData = [];
      let newEntry = this.tempArrExtract.filter((rec) => {
        if (rec.ReceivedDate == this.dateToday && rec.Status == "Not yet reply")
          return rec;
      });
      this.ExtractedData = Object.assign(newEntry);
    },
    filterUnfinished() {
      // this.dataStatus = "UNFINISHED";
      this.ExtractedData = [];
      let unfinished = this.tempArrExtract.filter((rec) => {
        if (rec.Status == "Not yet reply") return rec;
      });
      this.ExtractedData = Object.assign(unfinished);
    },
    filterFollowUP() {
      // this.dataStatus = "UNFINISHED";
      this.ExtractedData = [];
      let followUp = this.tempArrExtract.filter((rec) => {
        let todayDate = moment().format("YYYY-MM-DD");
        if (
          todayDate >= rec.DueDate &&
          rec.Status == "Not yet reply" &&
          rec.FollowupStatus < 3
        )
          return rec;
      });
      let sortFollowUp = _.orderBy(
        followUp,
        [
          function (o) {
            return o.DueDate;
          },
        ],
        "asc"
      );
      // console.log(sortFollowUp);
      this.ExtractedData = Object.assign(sortFollowUp);
    },
    filterFinished() {
      // this.dataStatus = "FINISHED";
      this.ExtractedData = [];
      let varEx = this.tempArrExtract.filter((rec) => {
        if (rec.Status == "Finished") return rec;
      });
      let sortFinished = _.orderBy(
        varEx,
        [
          function (o) {
            return o.ReceivedDate;
          },
        ],
        "desc"
      );
      this.ExtractedData = Object.assign(sortFinished);
    },
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
