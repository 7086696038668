import axios from "axios";
import keys from "../../alert";

export default {
  //************************//
  //  GET FOLLOWUP OVERDUE  //
  //************************//

  Get(query) {
    return Promise.resolve(
      axios.get(
        `${keys.auth.BaseUrl}/followupOverdue`,
        { params: query },
        keys.auth.apiKey
      )
    );
  },
  //************************************//
  //  INSERT DATA IN FOLLOWUP OVERDUE   //
  //************************************//

  Post(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/followupOverdue`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
};
