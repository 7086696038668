const xlsx = require("json-as-xlsx")
const axios = require("axios")
const moment = require("moment")

class Extraction {
    constructor() {
    }
    printReports(url,apikey){
        axios.get(url, apikey)
        .then((res)=>{
            res.data.filter(rec=>{
                rec.ReceivedDate = rec.ReceivedDate ? moment(rec.ReceivedDate).format("YYYY-MM-DD") : ''
                rec.ContentOfInquiry = rec.ContentOfInquiry.split("<br>").join(" ")
            })
        let data = [
            {
              sheet: "HRD Inquiry Data Extraction",
              columns: [
                { label: "Sent Date", value: "ReceivedDate" },
                { label: "Customer Code", value: "CustomerCode" },
                { label: "Sender Name", value: "SenderName" },
                { label: "Department", value: "Department" },
                { label: "Handle Email", value: "HandleEmail" },
                { label: "Handle Staff", value: "HandleStaff" },
                { label: "SubHandle Staff", value: "SubHandleStaff" },
                { label: "Inquiry Kind", value: "InquiryKind" },
                { label: "Job Kind", value: "JobKind" },
                { label: "Subject", value: "Subject" },
                { label: "Content of Inquiry", value: "ContentOfInquiry" },
              ],
              content:res.data
            },
          ]
          let settings = {
            fileName: "HRD Inquiry Data Extraction", // Name of the resulting spreadsheet
            extraLength: 3, // A bigger number means that columns will be wider
            writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
          }
          xlsx(data, settings) // Will download the excel file
        })
      }
  }
  module.exports = {
    Extraction : Extraction
}