import axios from "axios";

const allConnectionAPI = {

  ResidenceName(codeNumber) {
    return new Promise((resolve, reject) => {
      axios
        .get(`http://hrdapps78:4144/customer/ModName/${codeNumber}`, {
          headers: {
            'Content-Type': 'application/json; charset=shift-jis',
        }
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  ConstBranchOffice(ExhibitionCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `http://hrdapps72:9220/GetSpecificContractExhibitions/${ExhibitionCode}`
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  //****************************************//
  //       CI INFORMATION DESIGN OFFICE     //
  //****************************************//
  DesignOffice(codeNumber) {
    return new Promise((resolve, reject) => {
      axios
        .get(`http://hrdapps72:9220/GetDesignOffice/${codeNumber}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  //********************************//
  //       CI INFORMATION           //
  //   EX. planner || salesman etc  //
  //********************************//

  CustomerInformation(codeNumber) {
    return new Promise((resolve, reject) => {
      axios
        .get(`http://hrdapps72:9220/GetCustomerInformationShared/${codeNumber}`, {
          headers: {
            'Content-Type': 'application/json; charset=shift-jis',
        }
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
//********************************//
  //       CI INFORMATION           //
  //   EX. PlannerLeader  //
  //********************************//

  CustomerCode(codeNumber) {
    return new Promise((resolve, reject) => {
      axios
        .get(`http://10.168.72.116:9220/getPlannerByCustomerCode/${codeNumber}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  //******************************//
  //   JAPANESE EMPLOYEE MASTER   //
  //******************************//
  JapaneseEmployeeCI(EmployeeID) {
    return new Promise((resolve, reject) => {
      axios
        .get(`http://hrdapps72:9220/GetPerEmployeesShared/${EmployeeID}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  //******************************//
  //   HRD DEPARTMENT LIST API    //
  //******************************//
  HRDDepartmentlist() {
    return new Promise((resolve, reject) => {
      axios
        .get("http://HRD-ADMINWEB/api/company/department/hrd", {
          headers: {
            "master-api": "db588403f0a1d3b897442a28724166b4",
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  //**********************************************//
  //   JAPANESE EMPLOYEEE LOCAL MASTERLIST API    //
  //**********************************************//
  JapaneseEmployeeParams(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://1ze9v428i9.execute-api.us-east-2.amazonaws.com/prod/getUsers/${id}`,
          {
            headers: {
              "x-api-key": "JUe7mHXOhR6ziaSDHXUDJ310nTfdsH8s1ph44z2q",
            },
          }
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },

  //*************************//
  //   HRD MASTERLIST API    //
  //************************//

  HRDSpecificMasterlist(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`http://hrdapps70:3001/api/employees/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
};

export default allConnectionAPI;
